<template>
  <el-dialog
    class="editDialog"
    :append-to-body="true"
    width="600px"
    :visible.sync="showEditDialog"
    :close-on-click-modal="false"
    :destroy-on-close="true"
    @close="$emit('update:isShow', false)"
    :show-close="true">
    <div slot="title" class="title">{{ options.id ? '编辑' : '新增' }}字典</div>

    <div class="content" v-loading="loading">
      <el-form label-width="9em" :model="form" :rules="rules" ref="form">
        <el-form-item label="父级字典" prop="parentId" ref="parentId">
          <Treeselect
            :normalizer="normalizer"
            @input="changeParentId"
            v-model="form.parentId"
            :options="treeselectOptions"
            placeholder="请选择父级字典" />
        </el-form-item>
        <el-form-item label="字典名称" prop="dictName">
          <el-input
            @change="nameChange"
            v-model="form.dictName"
            placeholder="请输入字典名称"
            @keyup.enter.native="submit"></el-input>
        </el-form-item>

        <el-form-item label="字典值" prop="dictVal">
          <el-input
            v-model="form.dictVal"
            placeholder="请输入字典值"
            @keyup.enter.native="submit"></el-input>
        </el-form-item>
        <el-form-item label="排序号（升序）" prop="sort">
          <el-input
            v-model="form.sort"
            type="number"
            class="numrule"
            placeholder="请输入字典排序号"
            @keyup.enter.native="submit"></el-input>
        </el-form-item>

        <el-form-item label="备注" prop="remark">
          <el-input
            type="textarea"
            v-model="form.remark"
            placeholder="请输入备注"
            @keyup.enter.native="submit"></el-input>
        </el-form-item>
        <!-- 批量 -->
        <!-- <el-form-item label="批量" prop="plName">
          <el-input
            type="textarea"
            v-model="plName"
            placeholder="批量"
            @keyup.enter.native="submit"></el-input>
        </el-form-item> -->
      </el-form>
    </div>

    <div slot="footer" v-show="!loading">
      <!-- <el-button type="primary" @click="plAdd">转数组</el-button>
      <el-button type="primary" @click="nextAdd">下一个</el-button>
      <el-button type="primary" @click="nextSave">不关窗保存</el-button> -->
      <el-button type="success" :loading="submitLoading" @click="submit">保存</el-button>
      <el-button type="info" plain @click="$emit('update:isShow', false)">取消</el-button>
    </div>
  </el-dialog>
</template>
<script>
import { initTreeData } from '@/util/common'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
export default {
  components: {
    Treeselect: () => import('@riophae/vue-treeselect'),
  },
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      default: function () {
        return {}
      },
    },
    rulesData: {
      type: Array,
      default: function () {
        return []
      },
    },
    treeselectOptions: {
      type: Array,
      default: function () {
        return []
      },
    },
  },
  data() {
    return {
      showEditDialog: false,
      loading: true,
      submitLoading: false,
      form: {},
      rules: {
        parentId: [{ required: true, message: '请选择父级字典', trigger: ['blur', 'change'] }],
        dictName: [{ required: true, message: '请输入字典名称', trigger: ['blur', 'change'] }],
        dictVal: [
          { required: true, message: '请输入字典值', trigger: ['blur', 'change'] },
          {
            trigger: ['change', 'blur'],
            validator: (rule, value, callback) => {
              if (
                this.rulesData.some(r => {
                  return r.id != this.form.id && r.val == value
                })
              ) {
                callback(new Error('字典值不能重复'))
              }
              callback()
            },
          },
        ],
      },
      plName: '',
      arrayData: [],
      currIndex: 0,
    }
  },
  inject: ['refresh'],
  watch: {
    isShow: {
      immediate: true, //初始化立即执行
      handler: function (newVal) {
        // this.$emit('update:loading', false);
        this.showEditDialog = newVal
        this.currIndex = 0
      },
    },
    options: {
      immediate: true, //初始化立即执行
      deep: true, //对象深度监测
      handler: function (newVal, oldVal) {
        this.form = newVal
        this.loading = false
      },
    },
  },
  created() {},
  methods: {
    nextAdd(){
      let item = this.arrayData[this.currIndex]
      if(item == null || item == '' || item == undefined){
        this.$message.error('请输入字典名称')
        return
      }
      this.form.dictName = item
      this.form.dictVal = item.getPinyin('_')
      if (this.form.id) {
        delete this.form.id
      }
    },
    nextSave(){
      this.submitLoading = true

      this.$api.dict.add(this.form)
        .then(res => {
          this.submitLoading = false
          // this.refresh()
          this.currIndex = this.currIndex + 1
          this.$message.success('操作成功！')
        })
        .catch(err => {
          this.submitLoading = false
          console.log(err)
        })
    },
    plAdd(){

      this.arrayData = this.plName.split("\n");
      console.log(this.arrayData)
      // let obj = {}
      // array.forEach(e => {
      //   if(e){
      //     obj = {}
      //     obj.dictName = e
      //     obj.dictVal = e.getPinyin('_')
      //     obj.dictType = this.form.dictType
      //     obj.parentId = this.form.parentId
      //     this.$api.dict.add(obj).then(res => {
      //       this.$message.success('操作成功！')
      //     })
      //   }
      // })
    },
    nameChange(a) {
      if (!this.form.dictVal) {
        this.form.dictVal = a.getPinyin('_')
      }
    },
    normalizer(node) {
      return {
        id: node.id,
        label: node.name,
        children: node.children,
      }
    },
    changeParentId() {
      this.$refs.form.validateField('parentId')
    },
    submit() {
      this.$refs.form.validate((valid, obj) => {
        if (valid) {
          this.submitLoading = true
          var key = this.form.id ? 'edit' : 'add'

          this.$api.dict[key](this.form)
            .then(res => {
              this.submitLoading = false
              this.refresh()
              this.$message.success('操作成功！')
            })
            .catch(err => {
              this.submitLoading = false
              console.log(err)
            })
        } else {
          this.scrollView(obj)
        }
      })
    },
  },
}
</script>
<style lang="scss" scoped>
/deep/.numrule input::-webkit-outer-spin-button,
/deep/.numrule input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
/deep/.numrule input[type='number'] {
  -moz-appearance: textfield;
}
</style>
